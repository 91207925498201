import React from 'react';
import CopyButton from './CopyButton';

const Result = ({ message, index }) => {
  return (
    <div
      className={`card mb-2 ${
        localStorage.getItem('userTheme') === 'dark' ? 'bg-dark' : ''
      }`}
    >
      <div className="card-body">
        <h5 className="card-title">{index + 1}</h5>
        <p className="card-text">{message}</p>
        <CopyButton text={message}></CopyButton>
      </div>
    </div>
  );
};

export default Result;
