import React from 'react';

const Header = ({ onAdd }) => {
  return (
    <div className="mt-5 mb-4">
      <h1>Message Splitter</h1>
      <button
        className={`btn btn-secondary btn-sm ${
          localStorage.getItem('userTheme') === 'dark' ? 'bg-dark' : ''
        }`}
        onClick={onAdd}
      >
        Settings
      </button>
    </div>
  );
};

export default Header;
