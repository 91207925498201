import Result from './Result';

const Results = ({ results }) => {
  return (
    <div id="result" className="mt-2">
      {results.map((result, index) => (
        <Result key={index} message={result} index={index} />
      ))}
    </div>
  );
};

export default Results;
