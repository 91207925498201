export default function noWordBreak(message, maxLength) {
  let len = parseInt(maxLength);
  let curr = len;
  let prev = 0;
  let output = [];

  while (message[curr]) {
    output.push(message.substring(prev, curr));
    prev = curr;
    curr += len;
  }
  output.push(message.substring(prev));
  console.log(output);
  return output;
}
