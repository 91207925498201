import { useState, useEffect } from 'react';
import MsgForm from './components/MsgForm';
import Header from './components/Header';
import Results from './components/Results';
import noWordBreak from './splitters/noWordBreak';
import Settings from './components/Settings';
import wordBreak from './splitters/wordBreak';

function App() {
  const [maxLength, setMaxLength] = useState(1900);
  useEffect(() => {
    if (localStorage.getItem('maxLength') !== null) {
      setMaxLength(JSON.parse(localStorage.getItem('maxLength')));
    }
  }, []);
  const [results, setResults] = useState([]);
  const [useWordBreak, setWordBreak] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('useWordBreak') !== null) {
      setWordBreak(JSON.parse(localStorage.getItem('useWordBreak')));
    }
  }, []);
  const [showSettings, setShowSettings] = useState(false);

  const splitMessage = (message) => {
    let split = [];
    if (!useWordBreak) {
      split = noWordBreak(message.text, maxLength);
    } else {
      split = wordBreak(message.text, maxLength);
    }
    setResults([]);
    setResults(split);
  };

  const changeMaxlength = (len) => {
    localStorage.setItem('maxLength', String(len));
    setMaxLength(len);
  };

  const toggleWordBreak = () => {
    localStorage.setItem('useWordBreak', String(!useWordBreak));
    setWordBreak(!useWordBreak);
  };

  return (
    <div className="App">
      <main className="main container grid-md">
        <Header onAdd={() => setShowSettings(!showSettings)} />
        {showSettings && (
          <Settings
            useWordBreak={useWordBreak}
            toggleWordBreak={toggleWordBreak}
            maxLength={maxLength}
            changeMaxLength={changeMaxlength}
          />
        )}
        <MsgForm onAdd={splitMessage}></MsgForm>
        {results.length > 0 && <Results results={results} />}
      </main>
    </div>
  );
}

export default App;
