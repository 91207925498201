import React from 'react';

const CopyButton = ({ text }) => {
  const onClick = (e) => {
    e.preventDefault();
    let textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };
  return (
    <button onClick={onClick} className="btn btn-primary btn-block">
      Copy
    </button>
  );
};

export default CopyButton;
