import React from 'react';

const Settings = ({
  useWordBreak,
  toggleWordBreak,
  maxLength,
  changeMaxLength,
}) => {
  return (
    <div
      className={`card mb-4 mt-2 ${
        localStorage.getItem('userTheme') === 'dark' ? 'bg-dark' : ''
      }`}
    >
      <div className="card-body">
        <div className="form-group">
          <label className="form-switch" htmlFor="toggleWordBreak">
            <input
              checked={useWordBreak}
              onChange={toggleWordBreak}
              type="checkbox"
              id="toggleWordBreak"
            />
            <i class="form-icon"></i>Break Words
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="maxLength" className="form-label">
            Max Length
          </label>
          <input
            type="number"
            min={0}
            value={maxLength}
            onChange={(e) => changeMaxLength(e.target.value)}
            name=""
            className="form-input"
            id="maxLength"
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
