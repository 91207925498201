import { useState } from 'react';

const MsgForm = ({ onAdd }) => {
  const [text, setText] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    if (!text) {
      return;
    }
    onAdd({ text });
    setText('');
  };
  return (
    <form onSubmit={onSubmit} className="mt-2">
      <div className="form-group">
        <label htmlFor="messageInput" className="form-label">
          Long Message
        </label>
        <textarea
          onChange={(e) => setText(e.target.value)}
          className="form-input"
          value={text}
          id="messageInput"
          cols="60"
          rows="10"
        />
      </div>
      <input
        type="submit"
        className="btn btn-primary btn-block"
        value="Split"
      />
    </form>
  );
};

export default MsgForm;
